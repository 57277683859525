import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3df7d7b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload" }
const _hoisted_2 = { class: "upload__input ant-input" }
const _hoisted_3 = ["multiple", "accept"]
const _hoisted_4 = {
  key: 0,
  class: "upload__list"
}
const _hoisted_5 = { class: "upload__filename" }
const _hoisted_6 = { class: "upload__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        multiple: _ctx.multiple,
        ref: "originalInputRef",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.afterUpload && _ctx.afterUpload(...args))),
        type: "file",
        accept: _ctx.accept
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass([
          'upload__placeholder',
          _ctx.inputText === _ctx.placeholder ? 'upload__placeholder--empty' : '',
        ])
      }, _toDisplayString(_ctx.inputText), 3 /* TEXT, CLASS */),
      _createVNode(_component_a_button, {
        class: "upload__open",
        onClick: _ctx.openExplorer,
        type: _ctx.uploadButtonType,
        size: _ctx.size,
        shape: _ctx.shape
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonTitle), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick", "type", "size", "shape"])
    ]),
    (_ctx.multiple)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "upload__file"
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(file.name), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_button, {
                  class: "upload__remove",
                  size: "small",
                  type: "link",
                  onClick: ($event: any) => (_ctx.removeFile(index))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_CloseOutlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.removeTitle), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}