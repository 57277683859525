
import { computed, defineComponent, PropType, Ref, ref, watch } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";

export const UploadComponent = defineComponent({
  name: "UploadComponent",
  components: { CloseOutlined },
  props: {
    value: {
      type: Object as PropType<Array<File>>,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => "middle",
    },
    accept: {
      type: String,
      default: () => "",
    },
    shape: {
      type: String,
      default: () => "",
    },
    buttonTitle: {
      type: String,
      default: () => "Select file",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    removeTitle: {
      type: String,
      default: () => "Remove",
    },
    beforeUpload: {
      type: Function,
      default: () => {
        return () => true;
      },
    },
    uploadButtonType: {
      type: String,
      default: () => "tertiary",
    },
  },
  emits: ["update:value", "change"],
  setup(props, { emit }) {
    const files: Ref<Array<File>> = ref([]);
    const originalInputRef = ref();
    const openExplorer = () => {
      originalInputRef.value.click();
    };

    watch(
      () => props.value,
      (nv) => {
        if (nv) {
          files.value = props.value;
        }
      }
    );

    const removeFile = (index: number) => {
      files.value.splice(index, 1);
    };

    const afterUpload = () => {
      if (props.beforeUpload(Array.from(originalInputRef.value.files))) {
        if (props.multiple) {
          files.value = files.value.concat(
            Array.from(originalInputRef.value.files)
          );
        } else {
          files.value = Array.from(originalInputRef.value.files);
        }

        emit("update:value", files.value);
        emit("change", files.value);
      }
    };

    return {
      originalInputRef,
      openExplorer,
      afterUpload,
      files,
      removeFile,
      inputText: computed(() => {
        if (!files.value[0]) return props.placeholder;
        else if (props.multiple) {
          return props.placeholder;
        }
        return (files.value[0] as File).name;
      }),
    };
  },
});

export default UploadComponent;
